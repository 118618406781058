import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import ClickButton from 'cccisd-click-button';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import IconWrong from 'cccisd-icons/cancel-circle2';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import pawnQuery from './pawn.graphql';
import deplomentQuery from './deploymentList.graphql';

const siteHandles = [
    'rdl',
    'rfa',
    'rrwc',
    'rsk',
    'rssp',
    'romo',
    'rms',
    'rls',
    'rfz',
    'rdp',
    'rbm',
    'rsa',
    'rtp',
    'rscp',
    'rise',
    'ric',
    'rlp',
    'rdcp',
    'rua',
    'rnne',
    'rdde',
    'rrs',
];

const RocketShipPage = () => {
    const { studyString } = useParams();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [deployment, setDeployment] = useState(null);
    const [respondentPawn, setRespondentPawn] = useState(null);
    const [deploymentError, setDeploymentError] = useState('');
    const [studyNameError, setStudyNameError] = useState('');

    useEffect(() => {
        async function fetchDeployment() {
            const resp = await client.query({
                query: deplomentQuery,
                variables: {
                    assignmentHandle: studyString,
                },
                fetchPolicy: 'network-only',
            });

            const deploymentList = _get(resp, 'data.flows.assignment.deploymentList', []) || [];
            if (deploymentList.length === 0) {
                setDeploymentError('The study is not open at this time.');
                setIsInitialLoad(false);
                return;
            }

            const openDeployment = deploymentList.find(d => d.isOpen);
            if (!openDeployment) {
                const closedMessage = _get(
                    deploymentList,
                    '[0].settings.closedMessage',
                    'The study is configured incorrectly. Please contact an administrator.'
                );
                setDeploymentError(closedMessage);
            } else {
                setDeployment(openDeployment);
            }

            setIsInitialLoad(false);
        }

        if (!siteHandles.includes(studyString)) {
            setDeploymentError('This is not the correct link. Please double check the URL.');
            setIsInitialLoad(false);
        } else {
            fetchDeployment();
        }
    }, []);

    async function onSubmit({ studyName }) {
        setStudyNameError('');
        studyName = studyName.toLowerCase();
        if (!studyName) {
            setStudyNameError('Please provide a code');
            return;
        }

        const resp = await client.query({
            query: pawnQuery,
            variables: {
                studyName,
                deploymentId: deployment.deploymentId,
            },
            fetchPolicy: 'network-only',
        });

        const pawn = _get(resp, 'data.flows.deployment.respondent.pawn.pawn', null);
        if (!pawn || !pawn.pawnId || !pawn.respondentHash) {
            setStudyNameError('Wrong code. Please try again.');
        } else {
            setRespondentPawn(pawn);
        }
    }

    if (isInitialLoad) {
        return (
            <div className={style.initialLoader}>
                <Loader loading type="inline" />
            </div>
        );
    }

    if (deploymentError) {
        return (
            <p className={`text-danger ${style.errorMessage}`}>
                <Html content={deploymentError} />
            </p>
        );
    }

    if (deployment && respondentPawn) {
        return <Redirect to={`/d/${deployment.hash}/${respondentPawn.respondentHash}`} />;
    }

    return (
        <Formik onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
                <Form>
                    <div className={style.field}>
                        <Field name="studyName">
                            {({ field, form }) => {
                                function onChangeField(val) {
                                    setStudyNameError('');
                                    field.onChange(val);
                                }

                                return (
                                    <CccisdInput
                                        form={form}
                                        field={{ ...field, onChange: onChangeField }}
                                        autoFocus
                                        label="Your Code:"
                                    />
                                );
                            }}
                        </Field>
                    </div>
                    <ClickButton
                        className="btn btn-lg btn-primary"
                        type="submit"
                        title="Start"
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                    />
                    {studyNameError && (
                        <p className={`text-danger ${style.studyNameError}`}>
                            <IconWrong />
                            &nbsp;&nbsp;{studyNameError}
                        </p>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default RocketShipPage;
